export const arrayMixin = {
  data() {
    return {
    }
  },
  methods: {
    array_hasObjectByKeyValue: function(ar, key, value) {
      return this.array_getIndexForObjectWithKeyValue(ar, key, value) != -1
    },
    array_getIndexForObjectWithKeyValue(ar, key, value, compareByValueTypeAlso = false) {
      return ar.findIndex(element => {
        if (compareByValueTypeAlso) {
          return element[key] === value
        }
        return element[key] == value
      })
    }
  }
}